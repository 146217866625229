import React from 'react'
import Footer from './footer'
import Header from './header'
import Nav from './nav'
import '../styles/index.scss'

import layoutStyles from '../styles/layout.module.scss'

const Layout = props => {
  return (
    <div className={layoutStyles.wrapper}>
      <Header />
      <div className={layoutStyles.site}>
        <div className={layoutStyles.content}>{props.children}</div>
        <Footer />
      </div>
      <Nav active={false} />
    </div>
  )
}
export default Layout
