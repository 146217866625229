import React from 'react'
import { navigate } from 'gatsby'
import styles from '../styles/header.module.scss'
import logo from '../images/logo.svg'

const Header = () => {
  const onClickHandler = () => navigate('/')

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
      <div
        className={styles.logo}
        onClick={() => onClickHandler()}
      >
        <img src={logo} alt="logo" width="40" />
      </div>
      <div
        className={styles.brand}
        onClick={() => onClickHandler()}
      >Sharksmouth <span className={styles.light}>Press</span></div>
      </div>
    </div>
  )
}

export { Header as default }
