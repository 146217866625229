import React from "react"

import footerStyles from '../styles/footer.module.scss'

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return <footer className={footerStyles.footer}>&copy;{`2019-${year} Sharksmouth Press`}</footer>
}

export default Footer
