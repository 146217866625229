import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
// import ContactForm from '../components/contactForm/contactForm'
import styles from '../styles/contact.module.scss'

export default () => {
  return (
    <Layout>
      <Head title="Contact" />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>Contact</h1>
        </div>
        <div className={styles.content}>
          
          <h2>Contact</h2>

          <form
            name="Contact Form"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Contact Form" />

            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input type="text" name="name" placeholder="Your name" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Your email</label>
              <input type="email" name="email" placeholder="Your email" required />
              <small>
                We will <strong>never</strong> share your email!
              </small>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                rows="3"
                placeholder="Type your message here"
                required
              />
            </div>

            <button type="submit">Send your message</button>
          </form>
        </div>
      </div>
    </Layout>
  )
}
