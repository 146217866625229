import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Spin } from 'react-burgers'

import styles from '../styles/nav.module.scss'

const Nav = props => {
  const [isActive, setActive] = useState(false)

  const onClickNav = (to) => {
    setActive(false)
    setTimeout(() => {
      navigate(to)
    }, 400)
  }

  const navStyles = isActive ? [styles.nav, styles.active].join(' ') : styles.nav
  return (
    <>
      <div className={styles.burgerContainer} >
        <Spin
          onClick={() => {
            setActive(!isActive)
          }}
          active={isActive}
          width={46}
          lineHeight={4}
          lineSpacing={8}
          borderRadius={2}
          color={isActive ? "#7b1300" : "#333"}
        />
      </div>
      <div className={navStyles}>
        <div className={styles.container}>
          <div className={styles.pseudoLink} onClick={() => onClickNav('/')}>Home</div>
          <div className={styles.pseudoLink} onClick={() => onClickNav('/publications')}>Publications</div>
          <div className={styles.pseudoLink} onClick={() => onClickNav('/guidelines')}>Submission Guidelines</div>
          <div className={styles.pseudoLink} onClick={() => onClickNav('/about')}>About</div>
          <div className={styles.pseudoLink} onClick={() => onClickNav('/contact')}>Contact</div>
        </div>
      </div>
    </>
  )
}

export { Nav as default }
